.jqvmap-label
{
	position: absolute;
	display: none;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #292929;
	color: white;
	font-family: sans-serif, Verdana;
	font-size: smaller;
	padding: 3px;
  pointer-events:none;
}
.jqvmap-pin {
  pointer-events:none;
}
.jqvmap-zoomin, .jqvmap-zoomout
{
	position: absolute;
	left: 10px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #000000;
	padding: 3px;
	color: white;
	width: 10px;
	height: 10px;
	cursor: pointer;
	line-height: 10px;
	text-align: center;
}
.jqvmap-zoomin
{
	top: 10px;
}
.jqvmap-zoomout
{
	top: 30px;
}
.jqvmap-region
{
  cursor: pointer;
}
.jqvmap-ajax_response
{
  width: 100%;
  height: 500px;
}

@import './src/scss/_imports/_global-import';

.jqvmap-zoomin, .jqvmap-zoomout {
    width: 1.6rem;
    height: 1.6rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 3px;

    background-image: linear-gradient(to top, #f5f5f5, #f1f1f1);
	color: #444;
	border: 1px solid rgba(0,0,0,0.1);
	box-shadow: none;

    left: 0;
    top: 0;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
} 

.jqvmap-zoomout {
	top: $panel-spacer-x;
}

.p-0 .jqvmap-zoomin, 
.p-0 .jqvmap-zoomout {
	left: $panel-spacer-x;
	top: $panel-spacer-x;
}

.p-0 .jqvmap-zoomout {
	top: $panel-spacer-x + 2rem;
}

.jqvmap-bg-ocean {
	background-color: #eafeff;
}